.swiper {
	width: 100%;
	padding-top: 50px;
	padding-bottom: 50px;
}

.swiper-slide {
	background-position: center;
	background-size: cover;
	width: 350px;
	height: 350px;
}

.swiper-slide img {
	display: block;
	width: 100%;
}
.swiper-wrapper {
	padding-bottom: 30px;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	bottom: 0px !important;
}
