:root {
	--mainColor: #ec0085;
}
.App {
	text-align: center;
	background-image: url(./files/bg-img.jpg) !important;
	background-size: contain !important;
	min-height: 100vh;
}

.hr {
	height: 17px !important;
	margin: -5px 0px 7px 0px !important;
	opacity: 1 !important;
	background-color: var(--mainColor) !important;
	-webkit-mask: url(./files/hr.svg) 50% 0 no-repeat !important;
	mask: url(./files/hr.svg) 50% 0 no-repeat !important;
}

.MuiInputBase-formControl::before,
.MuiInputBase-formControl::after,
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
	border-color: var(--mainColor) !important;
}

.MuiInputBase-input,
.MuiOutlinedInput-root,
.MuiInputLabel-root {
	background-color: transparent !important;
	color: var(--mainColor) !important;
}

.icons .MuiButtonBase-root {
	background-color: white !important;
	color: transparent !important;
	border: 1px solid var(--mainColor) !important;
	color: var(--mainColor) !important;
}
.icons .MuiButtonBase-root:hover {
	background-color: var(--mainColor) !important;
	color: transparent !important;
	color: white !important;
}
.donate .MuiButtonBase-root {
	color: white !important;
	border: 1px solid var(--mainColor) !important;
	background-color: var(--mainColor) !important;
}
.donate .MuiButtonBase-root:hover {
	color: var(--mainColor) !important;
	background-color: white !important;
}
.sendButton.MuiButtonBase-root {
	background-color: var(--mainColor) !important;
	color: transparent !important;
	color: white !important;
	border: 1px solid var(--mainColor) !important;
}
.MuiButtonBase-root {
	background-color: var(--mainColor) !important;
	color: transparent !important;
	color: white !important;
	border: 1px solid var(--mainColor) !important;
}

.MuiButtonBase-root:hover {
	background-color: white !important;
	color: transparent !important;
	color: var(--mainColor) !important;
}
.MuiButtonBase-root:hover .MuiListItemIcon-root {
	color: var(--mainColor) !important;
}
.MuiTableCell-head {
	color: var(--mainColor) !important;
}
.dashboard .MuiDrawer-paper {
	background-color: var(--mainColor) !important;
}
.swiper-pagination-bullet-active {
	background-color: var(--mainColor) !important;
}
.textColor,
.MuiFormHelperText-root {
	color: var(--mainColor) !important;
}
input.Mui-disabled,
.MuiOutlinedInput-root .Mui-disabled {
	-webkit-text-fill-color: var(--mainColor) !important;
	color: var(--mainColor) !important;
}
.borderColor {
	border-color: var(--mainColor) !important;
}
.bgColor {
	background-color: var(--mainColor) !important;
}

.donate input,
.donate textarea {
	color: var(--mainColor) !important;
	width: 100% !important;
	padding: 9px !important;
}
.donate input::placeholder,
.donate textarea::placeholder {
	color: var(--mainColor) !important;
	opacity: 70%;
}
.donate input:focus,
.donate textarea:focus {
	outline-color: var(--mainColor) !important;
	border-color: transparent !important;
}
.donate [disabled] {
	opacity: 0.6;
	cursor: not-allowed;
}
.pay {
	background: var(--mainColor) !important;
}
.payment button:disabled,
.payment button[disabled] {
	border: 2px solid var(--mainColor) !important;
	opacity: 70% !important;
	width: 100% !important;
	padding: 7px 0 !important;
	background-color: var(--mainColor) !important;
}
.payment button:disabled span,
.payment button[disabled] span {
	color: white !important;
	background-color: transparent !important;
	border: 0 !important;
	box-shadow: none !important;
}

.pay span {
	background-image: none !important;
	box-shadow: none !important;
	font-size: 15px !important;
}
.MuiAccordionSummary-content {
	justify-content: center !important;
}
.loading-logo {
	animation: rotation 8s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: var(--mainColor) !important;
}
.MuiListItem-root {
	border: 1px solid white !important;
}
.activeButton {
	background-color: white !important;
	color: var(--mainColor) !important;
	border: 1px solid var(--mainColor) !important;
}

.activeButton .MuiListItemIcon-root {
	color: var(--mainColor) !important;
}
.selectedRow {
	background-color: var(--mainColor) !important;
	color: white !important;
}
.selectedRow td {
	color: white !important;
	font-weight: bold;
}
.bannerPhoto {
	height: 80vh;
}
@media screen and (max-width: 600px) {
	.bannerPhoto {
		height: 40vh;
	}
}
.csvBtn:hover {
	background-color: var(--mainColor) !important;
	color: white !important;
}
.formPagePdf {
	width: 794px;
	margin: 0 auto;
	text-align: right;
}
.formPagePdf .page {
	border: 2px solid;
	margin: 1.3rem;
	padding: 1rem;
	overflow: hidden;
	height: 100%;
}
.formData {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.formDataImg {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.formData .inputField {
	padding: 9px;
	font-size: 17.5px;
	max-width: 350px;
	overflow: hidden;
}
.formData .inputField span {
	font-weight: bold;
}
.pdfImg {
	width: 355px;
	height: 450px;
	border: 1px solid !important;
}
.formData .inputField .p {
	width: 350px;
	padding: 3px;
	background-color: transparent;
	border-bottom: 1px dotted #333;
}
.formData .inputField .p span {
	font-weight: 400;
	font-size: 15px;
	padding-left: 5rem;
}

.pagination .MuiButtonBase-root {
	background-color: transparent !important;
	color: var(--mainColor) !important;
	min-width: 30px !important;
	height: 30px !important;
}
.pagination .Mui-selected {
	background-color: var(--mainColor) !important;
	color: white !important;
}
.MuiPagination-ul {
	justify-content: center;
}
